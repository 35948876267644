import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Widdersdorf: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Widdersdorf,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Widdersdorf"
          d="M309,548l2,10l-24,2l-4,10h-12l-6-8l-14,6l-8-8l-22,10l-6-8l-22,8l-10-14l-0.3,0.1c-1.3-4.7-17.5-30.7-14-32.9 c11.2-7.2,21.9-18,31-27.7c-0.1-0.3-7.3-11.4-7.3-11.7l11.8-11.6l0.5,0.1l4.7-4.5L223,480h18l10-10l14-2l22,64L309,548z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 206.0842 530.37)"
        >
          Widdersdorf
        </text>
      </g>
    </>
  );
});
